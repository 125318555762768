<template>
	<div class="root">
		<div class="container">
			<div class="title">
				<div class="title1">{{title[0]}}</div>
				<div class="title2">{{title[1]}}</div>
				<div class="title3">{{title[2]}}</div>
				<div class="btnContainer">
					<a class="btn" href="http://main.mingzhuziyou.top:30000/explore">浏览 Mingzhuziyou Git</a>
				</div>
			</div>
			<div>
			<img style="width: 600px;" src="../../public/img/cat.svg" />
			</div>
		</div>
		<img class="dog" src="../../public/img/dog.png" />
	</div>
</template>

<script>
	export default {
		name: 'Swiper',
		data() {
			return {
				title: ["在明主自由小组中根据自己的节奏学习", "Mingzhuziyou 因为成长中的开发者更需要伙伴",
					"在明主自由数据中心分享资料进行学习,通过 Mingzhuziyou Git 上的开放源代码加快创新速度,同时提升编程水平。"
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.dog{
		width: 450px;
	}
	.root {
		height: 750px;
		width: 100vw;
		background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);

		.container {
			display: flex;
			flex-direction: row;
		}

		.title {
			display: flex;
			flex-direction: column;
			width: 600px;
			padding-top: 80px;
			width: 500px;
			min-width: 500px;
			padding-left: 90px;
			text-align: initial;

			.title1 {
				font-size: 20px;
				font-weight: 600;
				color: #50e6ff;
				margin-bottom: 9px;
			}

			.title2 {
				font-size: 54px;
				color: #f4f5f6;
				font-weight: 600;
				margin-bottom: 12px;
			}

			.title3 {
				font-size: 18px;
				font-weight: 400;
				color: #fff;
				line-height: 1.3;
			}
		}

		.btnContainer {
			display: flex;
			width: 100%;
		}

		.btn {
			background-color: #89c402;
			border: 1px solid #6f9e02;
			border-radius: 4px;
			color: #1a1a1f;
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			padding: 10px 16px;
			text-align: left;
			margin-top: 12px;
			margin-left: 0;
		}
		.btn:hover{
			text-decoration: underline;
			color: #1a1a1f;
			background-color: #9bdd02;
		}
		.btn:active{
			text-decoration: underline;
			color: #1a1a1f;
			background-color: #9bdd02;
		}
	}
</style>
