import Vue from 'vue'
import App from './App.vue'
import router from './router'
import req from '../utils/req.js'

Vue.config.productionTip = false
Vue.prototype.$req=req

document.documentElement.style.fontSize = document.documentElement.clientWidth / 90 + "px";
window.addEventListener(
	"resize",
  function() {
    document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 90 + "px";
  },
  false
)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
