<template>
	<div class="root">
		<div class="title">
			<h2 style="font-size: 24px;">在云或模拟生产环境中测试 - 在 Mingzhuziyou 上创建面向未来的解决方案</h2>
		</div>
		<div class="container">
			<div class="box0 box leftPosition" @click="toWeb(0)">
				<img class="boxImg0" src="../../public/img/box0.png" />
				<div class="boxTitle">{{content[0].title}}</div>
				<div class="boxInfo">{{content[0].info}}</div>
			</div>
			<div class="childrenContainer" @click="toWeb(1)">
				<div class="box1 box rightPosition">
					<img class="boxImg" src="../../public/img/box1.png" />
					<div>
						<div class="boxTitle">{{content[1].title}}</div>
						<div class="boxInfo">{{content[1].info}}</div>
					</div>
				</div>
				<div class="box2 box rightPosition" @click="toWeb(2)">
					<img class="boxImg" src="../../public/img/box2.png" />
					<div>
						<div class="boxTitle">{{content[2].title}}</div>
						<div class="boxInfo">{{content[2].info}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container2">
			<img class="puzzleImg" src="../../public/img/puzzle.png" />
			<div class="textAndBtn">
				<h2 class="text">在明主自由小组中通过合作打破限制</h2>
				<a class="btn" @click="checkState()">检查 Mingzhuziyou 服务状态</a>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from './Footer.vue'
	export default {
		name: "Body",
		components: {
			Footer
		},
		data() {
			return {
				content: [{
					title: "利用 Mingzhuziyou Git 上的开放源代码加快创新速度",
					info: '使用擅长的语言和框架，在不同环境中无缝创作、协作和部署。'
				}, {
					title: "在 Mingzhuziyou 云上环境部署测试环境",
					info: '在云上测试环境中对开发的项目进行部署，提高项目保护、检测和应对威胁的能力。'
				}, {
					title: "方便备份、学习、分享体验",
					info: '通过明主自由数据中心的易操作性改进文件管理和学习体验。'
				}]
			}
		},
		methods: {
			toWeb(index) {
				if (index == 0) {
					window.location.href = "http://main.mingzhuziyou.top:30000/explore"
					return
				}
				if (index == 1) {
					window.location.href = "https://main.mingzhuziyou.top:5001"
					return
				}
				if (index == 2) {
					window.location.href = "https://main.mingzhuziyou.top:5001/drive"
					return
				}
			},
			checkState() {
				console.log("---")
				this.$router.push('/status')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.root {
		background-color: #f8f9fa;
		height: fit-content;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.title {
		font-size: 24px;
		margin-top: 180px;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		white-space:nowrap;
	}

	.boxTitle {
		margin-bottom: 9px;
		font-size: 20px;
		font-weight: 600;
		line-height: 1.3;
		color: #0062ad;
	}

	.container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 1280px;
		min-width: 1280px;
		height: 400px;
		margin-top: 60px;

		.box {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background-color: #fff;
			border-radius: 6px;
			box-shadow: 0 2px 5px rgba(0, 0, 0, .14);
			color: #4c4c51;
			padding: 24px;
			position: relative;
			border: 1px solid transparent;
		}

		.box0 {
			flex: 1;
			width: 570px;
			min-width: 570px;
		}

		.box1 {
			margin-bottom: 20px;
			height: 200px;
		}

		.box2 {
			height: 200px;
		}

		.box:hover {
			transition: all .2s ease-in-out;
			box-shadow: 0 5px 10px rgba(0, 0, 0, .14);
		}

		.leftPosition {
			display: flex;
			flex-direction: column;
			text-align: left;
		}

		.rightPosition {
			display: flex;
			flex-direction: row;
			text-align: left;
		}

		.boxImg0 {
			width: 300px;
		}

		.boxImg {
			width: 150px;
		}

		.childrenContainer {
			display: flex;
			flex-direction: column;
			flex: 1;
			margin-left: 20px;
			height: 400px;
		}

		.boxInfo {
			font-size: 16px;
			font-weight: 400;
			line-height: 1.5;
			color: #4c4c51;
			font-family: 'Segoe UI', SegoeUI, 'Segoe WP', Tahoma, Arial, sans-serif;
		}
	}

	.container2 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 150px;
		margin-bottom: 150px;

		.puzzleImg {
			width: 500px;
		}

		.textAndBtn {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			margin-right: 50px;

			.text {
				font-size: 36px;
			}

			.btn {
				background-color: transparent;
				border: 2px solid #0078d4;
				border-radius: 4px;
				color: #0062ad;
				display: inline-block;
				font-size: 14px;
				font-weight: 600;
				padding: 10px 16px;
				margin: auto auto auto 0;
				transition-property: background-color;
			}

			.btn:active {
				text-decoration: underline;
				background-color: #0078d4;
				color: #fff;
			}

			.btn:hover {
				text-decoration: underline;
				background-color: #0078d4;
				color: #fff;
			}
		}

	}
</style>
