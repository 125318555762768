<template>
  <div class="root">
		<Head></Head>
		<Notice></Notice>
		<Swiper></Swiper>
		<Body></Body>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from '../components/Head.vue'
import Notice from '../components/Notice.vue'
import Swiper from '../components/Swiper.vue'
import Body from '../components/Body.vue'
export default {
  name: 'Home',
  components:{ Head ,Notice,Swiper,Body }
}
</script>

<style lang="scss" scoped>
	
</style>
