<template>
	<div>
		<div class="footer">
			<li class="text1">Mingzhuziyou Git和明主自由数据中心为专用的系统。仅供授权的小组成员使用。</li>
			<div class="text2">
				Copyright © 2021 mingzhuziyou.top 保留所有权利。
			</div>
			<a class="text3" href="https://beian.miit.gov.cn/">赣ICP备2020011564号-1</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Footer",
		data() {
			return {

			};
		}
	}
</script>

<style lang="scss" scoped>
	.footer {
		background-color: #f4f5f6;
		width: 100vw;
		height: 120px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-family: Segoe UI, SegoeUI, Segoe WP, Tahoma, Arial, sans-serif;
		
		.text1{
			font-family: "SF Pro SC",sans-serif;
			font-size: 12px;
			line-height: 1.33337;
			font-weight: 400;
			color: #86868b;
		}
		.text2{
			font-size: 1em;
			font-family: inherit;
			font-weight: inherit;
			line-height: inherit;
			text-align: inherit;
			color: #86868b;
		}
		.text3{
			color: #666;
		}
		
	}
</style>
