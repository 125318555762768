<template>
	<div class="root">
		<div class="container">
			{{notice}}
		</div>
	</div>
</template>

<script>
	export default {
		name:'Notice',
		data(){
			return{
				notice: ''
			}
		},
		created() {
			this.init()
		},
		methods:{
			async init(){
				try{
					let res = await this.$req.get("/getNotification")
					this.notice=res.data.content
				}catch(err){
					this.notice="加载失败,请重试!"
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.container{
		width: 100vw;
		height: 50px;
		background-color: #0078d4;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		font-size: 14px;
		font-weight: 600;
	}
</style>
