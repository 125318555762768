<template>
	<div class="root">
		<div class="head">
			
			<!-- 图标及标题 -->
				<div class="icon" @click="toHome">
					<img src="../../public/icon/mzzy.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="title" @click="toHome">Mingzhuziyou</div>
			<!-- end -->
			
			<!-- 操作选项 -->
			<div class="optionList" v-for="(item,index) in optionList" :class="$route.path=='/status'&&item.title=='服务状态'?'optionListActive':''" @click="toWeb(item)" :key="index">
				<div>{{item.title}}</div>
			</div>
			<!-- end -->
			
		</div>
	</div>
</template>

<script>
	export default {
		name: "Head",
		data(){
			return{
				optionList:[{
					title:'Git',
					url: 'http://main.mingzhuziyou.top:30000/explore'
				},{
					title:'数据中心',
					url: 'https://main.mingzhuziyou.top:5001'
				},{
					title:'服务状态',
					url: '/state'
				}
				]
			}
		},
		methods:{
			toWeb(item){
				console.log(item)
				if(item.title=='服务状态'){
					this.$router.push("/status")
					return
				}
				window.location.href=item.url
			},
			toHome(){
					this.$router.replace('/')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.arrowStyle{
		width: 100%;
		height: 100%;
		padding-top: 6px;
		padding-left: 5px;
	}
	.head{
		width: 100vw;
		height: 50px;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		.icon{
			width: 30px;
			height: 30px;
			margin-left: 3.125rem;
			cursor: pointer;
		}
		.title{
			font-family: 'Segoe UI',SegoeUI,'Segoe WP',Tahoma,Arial,sans-serif;
			font-weight: 600;
			margin-left: 20px;
			font-size: 18px;
			cursor: pointer;
		}
		.optionList{
			cursor: pointer;
			display: flex;
			flex-direction: row;
			margin-left: 40px;
			font-size: 13px;
		}
		.optionListActive{
			color: #8b8b8b;
		}
		.optionList:active{
			padding: 4px;
			background-color: #bdc3c7;
		}
	}
</style>
